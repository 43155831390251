import { DimensionUnit, ProductRGBAColor, ProductType } from '../product';

export interface GroutSettings {
    id?: string;
    color: ProductRGBAColor;
    width: number;
    unit: DimensionUnit;
}

export const productsTypesSupportingGrout = [
    ProductType.bambooFlooring,
    ProductType.ceramic,
    ProductType.concrete,
    ProductType.engineeredHardwood,
    ProductType.floor,
    ProductType.hybridFloor,
    ProductType.laminate,
    ProductType.lvp,
    ProductType.lvtVinyl,
    ProductType.naturalStone,
    ProductType.polishedConcrete,
    ProductType.porcelain,
    ProductType.solidHardwood,
    ProductType.spc,
    ProductType.terrazzo,
    ProductType.tiles,
    ProductType.timber,
    ProductType.wall,
];

export const defaultGroutColor: ProductRGBAColor = { r: 204, g: 204, b: 204, a: 1 };

// 1mm, 2mm, 4mm, 6mm, 8mm, 10 mm in meters
export const defaultGroutWidths = [0.001, 0.002, 0.004, 0.006, 0.008, 0.01];

// Default value 6mm in meters
export const defaultGroutWidth = 0.006;
