import { ActionButton, CustomButtonTypes, FieldTranslation } from '@floori/models';
import {
    customActionButtonsIcons,
    customActionButtonsLabels,
    roomActionButtonsIcons,
    roomActionButtonsLabels,
    RoomActionButtonType,
} from './room-settings-button';

// Prefer not to change this to camelCase because of other enums and types
// eslint-disable-next-line @typescript-eslint/naming-convention
export const RoomActionBtnType = { ...RoomActionButtonType, ...CustomButtonTypes };
export type RoomActionBtnType = keyof typeof RoomActionBtnType;

export class RoomActionBtn<T extends ActionButton = any> {
    metadata!: Omit<T, 'id' | 'icon' | 'visibility' | 'text'>;
    visible = true;
    label: string | FieldTranslation = '';
    icon = '';
    afterAnimation?: boolean;
    readonly mobileAction?: boolean;

    constructor(
        public readonly type: RoomActionBtnType,
        public readonly id: string = '',
        metadata?: Partial<T> | T,
        mobileAction?: boolean,
    ) {
        if (type in RoomActionButtonType) {
            const safeType = type as RoomActionButtonType;
            this.label = roomActionButtonsLabels[safeType] || '';
            this.icon = roomActionButtonsIcons[safeType] || '';
            this.afterAnimation = false;
            this.visible = typeof metadata?.visibility === 'boolean' ? metadata?.visibility : true;
        } else if (type in CustomButtonTypes) {
            this.label =
                metadata?.text || customActionButtonsLabels[type as CustomButtonTypes] || '';
            this.icon = metadata?.icon || customActionButtonsIcons[type as CustomButtonTypes];
            this.visible = metadata?.visibility || false;
            this.afterAnimation = false;
            this.metadata = metadata || ({} as any);
        }
        this.mobileAction = mobileAction;
    }
}
