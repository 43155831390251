<ng-container *ngIf="!loading">
    <div class="floori-dialog-header">
        <span>{{ "cookies.header" | flTranslate }}</span>
    </div>
    <section floori-dialog-content>
        <p>{{ "cookies.content" | flTranslate }}</p>
    </section>
    <div floori-dialog-actions>
        <button floori-button class="floori-button outline big" [floori-dialog-close]="false">
            {{ "cookies.reject" | flTranslate }}
        </button>
        <button class="floori-button primary big" [floori-dialog-close]="true">
            {{ "cookies.accept" | flTranslate }}
        </button>
    </div>
</ng-container>
