import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
    HotpointProductPair,
    Product,
    ProductFilterValues,
    ProductTransform,
} from '@floori-web/models';
import {
    BreadcrumbItemDto,
    EpoxyPatterns,
    FloorPattern,
    GroutSettings,
    HashMap,
    PagedResponse,
    PlaneXYPosition,
    ProductExtended,
    ProductFilter,
    ProductFilterDefinitionDto,
    ProductFilterResponseDto,
    ProductsFilterData,
    ProductTileSize,
    ProductType,
    TextureTransitionType,
} from '@floori/models';
import { FilterValues } from '@floori-web/products/data-access';

export const FLOORI_PRODUCTS_PROVIDER = new InjectionToken('FLOORI_PRODUCTS_PROVIDER');

export interface ProductsProvider {
    activeProduct$: Observable<ProductExtended | undefined | null>;
    activeProducts$: Observable<ProductExtended[]>;
    activeEpoxyProducts$: Observable<{
        color: ProductExtended | null;
        chip: ProductExtended | null;
        density?: EpoxyPatterns;
    }>;
    lastRugPosition$: Observable<PlaneXYPosition | undefined>;
    hotpointProductMap$: Observable<Record<string, HotpointProductPair>>;
    visualizationUpdate$: Observable<boolean | undefined>;
    productsBaseData$: Observable<{
        filterDefinitions?: ProductFilterDefinitionDto[];
        breadcrumb?: BreadcrumbItemDto[];
    }>;
    groutProducts$: Observable<Product[]>;
    groutProductsLoading$: Observable<boolean>;
    currentItems$: Observable<PagedResponse<ProductFilterResponseDto> | undefined>;
    currentEpoxyChips$: Observable<PagedResponse<ProductFilterResponseDto> | undefined>;
    currentEpoxyColors$: Observable<PagedResponse<ProductFilterResponseDto> | undefined>;
    currentSettings$: Observable<ProductTransform>;
    selectedProducts$: Observable<ProductExtended[]>;
    selectFilterDefinitions$: Observable<ProductFilterDefinitionDto[] | undefined>;
    selectFilterValues$: Observable<ProductFilterValues>;
    filtersLoading$: Observable<boolean>;
    questionsData$: Observable<{
        questionsAnswered: boolean;
        questionsFilters: ProductFilter[] | undefined;
    }>;
    likedProducts$: Observable<ProductExtended[]>;
    likedProductsMap$: Observable<HashMap<ProductExtended>>;
    epoxyLoaded$: Observable<boolean>;
    productFields$: Observable<string[]>;

    clearProducts(): void;

    getCatalogId(breadcrumb: BreadcrumbItemDto[] | undefined): string;

    setFiltersLoading(value: boolean): void;

    setQuestionsAnswered(value: boolean): void;

    setQuestionsFilters(
        filterDefsMap: HashMap<ProductFilterDefinitionDto>,
        filters: FilterValues,
    ): void;

    patchFilters(
        filters: Partial<ProductsFilterData>,
        execute?: boolean,
        productType?: ProductType,
    ): void;

    filterProductsCall(
        filters: ProductsFilterData,
    ): Observable<[PagedResponse<ProductFilterResponseDto>, string]>;

    fetchProduct(productId: string, isGrout?: boolean): Observable<ProductExtended>;

    fetchInitialProduct(productId?: string, sku?: string, externalId?: string): void;

    fetchEpoxyColors(id: string): void;

    fetchEpoxyChips(id: string): void;

    fetchEpoxy(ids: [string, string]): void;

    fetchProductBase(productId?: string, sku?: string, externalId?: string): void;

    fetchGroutProducts(
        catalogId: string,
        page: number,
    ): Observable<[PagedResponse<ProductFilterResponseDto>, string]>;

    setEpoxyLoaded(loaded: boolean): void;

    setBreadcrumb(breadcrumb: BreadcrumbItemDto[]): void;

    clearBreadcrumb(): void;

    setActiveProduct(
        product?: ProductExtended,
        updateVisualization?: boolean,
        additional?: boolean,
    ): void;

    setLastRugPosition(position?: PlaneXYPosition): void;

    setEpoxyProduct(product?: ProductExtended, isColor?: boolean): void;

    setProductItems(items: PagedResponse<ProductFilterResponseDto>): void;

    toggleFavouriteProduct(product: ProductExtended): void;

    parseToFilterDefsCall(
        filterDefs: HashMap<ProductFilterDefinitionDto>,
        filters: FilterValues,
        currentValues?: HashMap<ProductFilter>,
    ): ProductFilter[];

    updateHotpointsMap(selectionMap: HashMap<boolean>): void;

    updateRotation(angle: number): void;

    updateTransition(value: number, type: TextureTransitionType): void;

    updateSize(size: ProductTileSize): void;

    updatePatternAndGrout(pattern: FloorPattern, grout?: GroutSettings): void;

    randomizePattern(): void;

    clearProduct(product?: ProductExtended | null): void;

    clearEpoxyColor(product?: ProductExtended | null): void;

    clearEpoxyChip(product?: ProductExtended | null): void;

    clearEpoxy(): void;

    updateDensity(density: EpoxyPatterns): void;

    applySettings(settings: ProductTransform): void;

    resetSettings(): void;

    verifySettings(product: ProductExtended): void;

    visualizeSettings(settings: ProductTransform): void;
}
