import { createReducer, on } from '@ngrx/store';
import { initialIntegrationState, IntegrationState } from '../state/integration.state';
import { integrationActions } from '../actions/integration.actions';

const setIntegrationType = on(
    integrationActions.setIntegrationType,
    (state: IntegrationState, { integrationType }): IntegrationState => ({
        ...state,
        integrationType,
    }),
);

export const integrationReducer = createReducer(initialIntegrationState, setIntegrationType);
