import { AssetModel3DDto } from '../common';
import { ProductTextureGenerationStatus } from './product-status';

export interface ProductTileSize {
    width: number;
    height: number;
    unit: DimensionUnit;
    groutId?: string;
    groutWidth: number;
    groutColor: string;
    groutUnit: string;
    groutEditable: boolean;
    prices: TilePrice[];
    isDefault: boolean;
    url?: string;
    originalUnit?: DimensionUnit;
    displayUnit: DimensionUnit;
    originalWidth: number;
    originalHeight: number;
    id?: string;
    model3D?: AssetModel3DDto;
    status?: ProductTextureGenerationStatus;
}

export interface TilePrice {
    price: string;
    currency: string;
    isDefault: boolean;
}

export enum DimensionUnit {
    cm = 'CM',
    inch = 'INCH',
    meter = 'M',
    mm = 'MM',
    ft = 'FEET',
}

export const imperialUnits = [DimensionUnit.inch, DimensionUnit.ft];
