import { catchError, forkJoin, map, Observable, of, Subscription, tap, withLatestFrom } from 'rxjs';
import {
    BaseTrackingProvider,
    FLOORI_GTM_PROVIDER,
    FLOORI_INTERACTION_PROVIDER,
    FlooriGtmProvider,
    FlooriInteractionProvider,
    FlooriTrackingProvider,
    TrackingTypes,
} from '@floori-web/tracking';
import { inject, Injectable, isDevMode, OnDestroy } from '@angular/core';
import { FLOORI_CONTEXT_ID, FLOORI_ENV } from '@floori-web/constants';
import {
    ConfigProvider,
    FLOORI_CONFIG_PROVIDER,
    FLOORI_STORAGE_PROVIDER,
    FlooriEnv,
    LocalStorageKeys,
    StorageProvider,
} from '@floori-web/models';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { CompanyConfig, HashMap } from '@floori/models';
import { devLog } from '@floori-web/tools';

@Injectable()
export class TrackingService implements FlooriTrackingProvider, OnDestroy {
    private readonly env = inject<FlooriEnv>(FLOORI_ENV);
    private readonly contextId = inject<string>(FLOORI_CONTEXT_ID);
    private readonly config = inject<ConfigProvider>(FLOORI_CONFIG_PROVIDER);
    private readonly storage = inject<StorageProvider>(FLOORI_STORAGE_PROVIDER);
    private readonly gtmTracking: FlooriGtmProvider | null = inject<FlooriGtmProvider>(
        FLOORI_GTM_PROVIDER,
        { optional: true },
    );
    private readonly interactionTracking = inject<FlooriInteractionProvider>(
        FLOORI_INTERACTION_PROVIDER,
        { optional: true },
    );
    private readonly trackers: Record<TrackingTypes, BaseTrackingProvider | null> = {
        [TrackingTypes.gtm]: this.gtmTracking,
        [TrackingTypes.interaction]: this.interactionTracking,
    };

    readonly availableTracking: TrackingTypes[] = [];
    private subscription = Subscription.EMPTY;

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    init(): Observable<void> {
        return forkJoin([this.gtmTrackingCall(), this.interactionTrackingCall()]).pipe(
            map(() => void 0),
        );
    }

    enableAllTracking(): void {
        if (this.gtmTracking) {
            this.gtmTracking.enable();
            this.gtmTrackingCall().subscribe();
        }
        this.interactionTracking?.enable();
    }

    disableAllTracking(): void {
        this.gtmTracking?.disable();
        this.interactionTracking?.disable();
    }

    private interactionTrackingCall(): Observable<void> {
        const interactionKey = this.env.interactionKey;
        return this.interactionTracking && interactionKey
            ? this.interactionTracking.init(interactionKey).pipe(
                  catchError(() => of(void 0)),
                  map(() => {
                      this.availableTracking.push(TrackingTypes.interaction);
                  }),
              )
            : of(void 0);
    }

    private gtmTrackingCall(): Observable<void> {
        return this.addGtm();
    }

    addGtm(): Observable<void> {
        if (!this.gtmTracking) {
            return of(void 0);
        }

        return fromPromise(this.gtmTracking.addGtmToDom()).pipe(
            catchError(() => of(void 0)),
            map(success => {
                if (success) {
                    this.availableTracking.push(TrackingTypes.gtm);
                }
            }),
        );
    }

    track(eventName: string, props: HashMap = {}, trackTypes?: Set<TrackingTypes>): void {
        this.subscription = this.storage
            .get(LocalStorageKeys.visitorId)
            .pipe(
                withLatestFrom(this.config.companyConfigRaw$),
                tap(([visitorId, companyConfig]) => {
                    if (!!companyConfig) {
                        this.iterateTrackProviders(
                            eventName,
                            props,
                            companyConfig,
                            visitorId || '',
                            trackTypes,
                        );
                    }
                }),
            )
            .subscribe();
    }

    private iterateTrackProviders(
        eventName: string,
        props: HashMap,
        config: CompanyConfig,
        visitorId: string,
        trackTypes?: Set<TrackingTypes>,
    ): void {
        const { company, id } = config || {};

        const finalProps = {
            ...structuredClone(props),
            contextId: this.contextId,
            companyId: id,
            visitorId,
            company,
        };
        if (!trackTypes?.size) {
            Object.entries(this.trackers).forEach(([type, tracker]) => {
                if (this.availableTracking.includes(type as TrackingTypes)) {
                    tracker?.track(eventName, finalProps);
                    this.trackLogs(eventName, finalProps, type as TrackingTypes);
                }
            });
            return;
        }

        trackTypes.forEach(trackType => {
            if (this.availableTracking.includes(trackType)) {
                this.trackers[trackType]?.track(eventName, finalProps);
                this.trackLogs(eventName, finalProps, trackType);
            }
        });
    }

    private trackLogs(eventName: string, props: HashMap, type: TrackingTypes): void {
        if (isDevMode() && this.env.showDebugData) {
            devLog(`Event: ${eventName}, type: ${type}.\n`, props);
        }
    }
}
