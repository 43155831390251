import { ProductTransform } from '@floori-web/models';
import {
    defaultGroutColor,
    defaultGroutWidth,
    DimensionUnit,
    FloorPattern,
    ProductFilter,
    ProductFilterValueType,
    ProductPlacementOptions,
    ProductType,
} from '@floori/models';

const tabletBreakpoint = 768;
const desktopBreakpoint = 1024;

export const screenBreakpoints: ScreenBreakpoints = {
    tablet: `${tabletBreakpoint}px`,
    tabletRaw: tabletBreakpoint,
    desktop: `${desktopBreakpoint}px`,
    desktopRaw: desktopBreakpoint,
} as const;

export const unitConstants = {
    feetToInch: 12,
    feetToMeter: 3.28571429,
    inchToFeet: 1 / 12,
    inchToMeter: 39.3701,
} as const;

export const textureTransitionScale = 50;

export const flooriConstants = {
    flooriLogo: 'assets/floori_horizontal.svg',
    flooriLogoVertical: 'assets/floori_vertical.svg',
    validImagesRegex: /([/\\.\-\w\s])*\.(?:jpg|jpeg|png)/i,
    visitorIdKey: 'visitorId',
    screenBreakpoints,
    cssVarPrefix: 'flc-',
    phoneFormat: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
    postalCode: /\\d*\\-\\d*/,
    emailFormat:
        // Cannot change length of this format as well as split it.
        // eslint-disable-next-line max-len
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    products: {
        pageLimit: 12,
    },
} as const;

export const defaultProductSettings: ProductTransform = {
    rotation: 0,
    transition: {
        x: 0,
        y: 0,
    },
    pattern: FloorPattern.random,
    grout: {
        color: defaultGroutColor,
        width: defaultGroutWidth,
        unit: DimensionUnit.mm,
    },
};

export const defaultFloorsPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.floors,
    ProductPlacementOptions.rugs,
];

export const defaultWallsPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.walls,
];

export const defaultStairsPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.stairs,
];

export const defaultDoorsPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.doors,
];

export const defaultDoorFramesPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.doorFrames,
];

export const defaultTrimsPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.trims,
];

export const defaultCeilingsPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.ceilings,
];

export const defaultCabinetsPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.cabinets,
];

export const defaultCountertopsPlacementOptions: ProductPlacementOptions[] = [
    ProductPlacementOptions.countertops,
];

export const defaultPlacementOptions: ProductFilter = Object.seal({
    routeName: 'po',
    isChildRelation: false,
    filterValueType: ProductFilterValueType.array,
    fieldName: 'placementOptions',
    value: [
        ...defaultFloorsPlacementOptions,
        ...defaultWallsPlacementOptions,
        ...defaultCeilingsPlacementOptions,
        ...defaultDoorsPlacementOptions,
        ...defaultDoorFramesPlacementOptions,
        ...defaultTrimsPlacementOptions,
        ...defaultCabinetsPlacementOptions,
        ...defaultStairsPlacementOptions,
        ...defaultCountertopsPlacementOptions,
    ],
});

export const nonFloorPlacementOptions = Object.freeze(
    Object.values(ProductPlacementOptions).filter(
        po => !defaultFloorsPlacementOptions.includes(po),
    ),
);

export const epoxyColorsFilter: ProductFilter = Object.seal({
    fieldName: 'type',
    filterValueType: ProductFilterValueType.single,
    isChildRelation: false,
    value: [ProductType.epoxyBase],
    routeName: 'ft',
});

export const epoxyChipsFilter: ProductFilter = Object.seal({
    fieldName: 'type',
    filterValueType: ProductFilterValueType.single,
    isChildRelation: false,
    value: [ProductType.epoxyChips],
    routeName: 'ft',
});

export const visualizationConstants = {
    minPortraitZoomWidth: 1200,
    aspectRatioZoomTolerance: 0.5,
} as const;

export interface ScreenBreakpoints {
    readonly tablet: string;
    readonly tabletRaw: number;
    readonly desktop: string;
    readonly desktopRaw: number;
}

export const roomsColumnsNumber = {
    mobile: 2,
    tablet: 4,
    desktop: 6,
} as const;

export const errorsToSkip = [
    '0 Unknown Error',
    `Failed to read the 'localStorage' property from 'Window': Access is denied for this document.`,
    'The operation is insecure.',
] as const;

export const productNotFound = 'Product not found';

export const productNotFoundErrors = [
    productNotFound,
    'No product with given criteria has been found',
    'The provided ID is invalid',
] as const;
