import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseTrackingProvider } from './base-tracking.provider';

export const FLOORI_INTERACTION_PROVIDER = new InjectionToken('FLOORI_INTERACTION_PROVIDER');

export interface FlooriInteractionProvider extends BaseTrackingProvider {
    init(key: string): Observable<void>;

    enable(): void;

    disable(): void;
}
