// Cannot change names of events to camelCase
/* eslint-disable @typescript-eslint/naming-convention */
import { createActionGroup, props } from '@ngrx/store';

export const cookiesActions = createActionGroup({
    source: 'Cookies',
    events: {
        'Handle Saved Consent': props<{ agreed: boolean }>(),
        'Open Dialog': props<{ show: boolean }>(),
    },
});
