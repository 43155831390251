import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EstimationDto, ProductExtended, ProductQuestion, RoomDto } from '@floori/models';
import { Size } from '@floori-web/visualization-models';
import { Hotpoint } from '../visualization';
import { RoomActionBtn } from '../room';
import { AllRooms } from './all-rooms';
import { ViewerSettings } from './viewer-settings';

export const FLOORI_ROOMS_PROVIDER = new InjectionToken('FLOORI_ROOMS_PROVIDER');

export interface RoomsProvider {
    rooms$: Observable<AllRooms>;
    activeRoom$: Observable<RoomDto | undefined>;
    roomHotpoints$: Observable<Hotpoint[]>;
    showLayout$: Observable<boolean>;
    showHotpoints$: Observable<boolean>;
    roomEstimation$: Observable<EstimationDto | undefined>;
    loadingRooms$: Observable<boolean>;
    selectCompare$: Observable<boolean>;
    selectZoom$: Observable<boolean>;
    showZoom$: Observable<boolean>;
    sawZoomTutorial$: Observable<boolean>;
    roomUploading$: Observable<boolean>;
    viewerSettings$: Observable<ViewerSettings>;
    selectCtaButtons$: Observable<RoomActionBtn[] | undefined>;
    selectVisualizationSize$: Observable<Size | undefined>;
    selectIsDynamicMode$: Observable<boolean>;

    fetchProductInRooms(
        productId?: string,
        sku?: string,
        externalId?: string,
    ): Observable<ProductExtended | undefined>;

    fetchQuestions(): Observable<ProductQuestion[]>;

    addSession(roomId: string): void;

    setActiveRoom(room: RoomDto): void;

    setHotpointsVisibility(visible: boolean): void;

    setZoomTutorial(): void;

    setEstimation(estimationDto: EstimationDto | undefined): void;

    setRoomUploading(uploading: boolean): void;

    getRoom(roomId: string): Observable<RoomDto>;

    loadRooms(): void;

    toggleLayoutVisibility(show: boolean): void;

    toggleCompare(compareOn?: boolean): void;

    toggleZoom(zoom: boolean): void;

    setShowZoom(show: boolean): void;

    updateRooms(room: RoomDto): void;

    setVisualizationSize(roomTextureSize?: Size): void;

    setDynamicMode(isDynamicMode: boolean): void;
}
