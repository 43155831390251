// Cannot change names of events to camelCase
/* eslint-disable @typescript-eslint/naming-convention */
import { IntegrationType } from '@floori/models';
import { createActionGroup, props } from '@ngrx/store';

export const integrationActions = createActionGroup({
    source: 'Integration',
    events: {
        'Set Integration Type': props<{ integrationType: IntegrationType }>(),
    },
});
