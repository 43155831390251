import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogHeaderComponent } from '@floori-web/ui/dialog-header';
import { TranslationsModule } from '@floori-web/translations';
import { FlooriDialogModule } from '@floori-web/ui/dialog';
import { FlooriButtonComponent } from '@floori-web/ui/button/src';

@Component({
    selector: 'floori-cookies-dialog',
    standalone: true,
    imports: [
        CommonModule,
        DialogHeaderComponent,
        TranslationsModule,
        FlooriDialogModule,
        FlooriButtonComponent,
    ],
    templateUrl: './cookies-dialog.component.html',
    styleUrls: ['./cookies-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesDialogComponent {
    private readonly cdr = inject(ChangeDetectorRef);
    loading = true;

    @HostBinding('class.floori-cookies-dialog') hostClass = true;

    constructor() {
        setTimeout(() => {
            this.loading = false;
            this.cdr.markForCheck();
        }, 200);
    }
}
