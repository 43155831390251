import { createReducer, on } from '@ngrx/store';
import { CookiesState } from '../state/cookies.state';
import { cookiesActions } from '../actions/cookies.actions';

const initialState: CookiesState = {
    agreed: true,
    show: false,
};

const handleSavedConsent = on(
    cookiesActions.handleSavedConsent,
    (state: CookiesState, { agreed }) =>
        ({
            ...state,
            agreed,
        } as CookiesState),
);

const openDialog = on(
    cookiesActions.openDialog,
    (state: CookiesState, { show }) =>
        ({
            ...state,
            show,
        } as CookiesState),
);

export const cookiesReducer = createReducer(initialState, openDialog, handleSavedConsent);
