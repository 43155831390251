import {
    AssetImageDto,
    DimensionUnit,
    FieldTranslation,
    FloorPattern,
    OS,
    ProductRGBAColor,
    ProductCombinedImage,
    ProductCustomField,
    ProductExtendedDto,
    ProductPlacementOptions,
    ProductTileSize,
    ProductType,
} from '@floori/models';
import { assetImageMapper } from '@floori-web/utils/pipes';
import { DynamicProduct } from '@floori-web/models';

export class ProductExtended {
    readonly id: string;
    readonly type?: ProductType;
    readonly sku?: string;
    readonly ean?: string;
    readonly externalId?: string;
    readonly manufacturer?: string;
    readonly supplier?: string;
    readonly placementOptions: ProductPlacementOptions[];
    readonly patterns?: FloorPattern[];
    readonly name?: FieldTranslation;
    readonly description?: FieldTranslation;
    readonly isGlossy?: boolean;
    readonly gltfUrl?: string;
    readonly usdzUrl?: string;
    readonly showDimensions?: boolean;
    readonly groutColor?: string;
    readonly groutWidth?: number;
    readonly groutUnit?: DimensionUnit;
    readonly custom?: ProductCustomField;
    readonly orderSampleUrl?: string;
    readonly roomTypes?: string[];
    readonly stockQuantity?: number;
    combined: ProductCombinedImage[] = [];
    image?: AssetImageDto | null;
    rgbaColor?: ProductRGBAColor | null;
    groupedTileOptions?: ProductTileSize[];
    tileOptions: ProductTileSize[] = [];
    width: number;
    height: number;
    countX?: number;
    countY?: number;
    defaultPattern?: FloorPattern;
    selectedSize?: ProductTileSize;
    parentId?: string;

    get isFloor(): boolean {
        return !this.isRug && !this.isModel3D;
    }

    get isRug(): boolean {
        return this.type === ProductType.rug;
    }

    get isModel3D(): boolean {
        return this.type === ProductType.model3d;
    }

    get isEpoxy(): boolean {
        return (
            this.type === ProductType.epoxyBase ||
            this.type === ProductType.epoxyChips ||
            this.type === ProductType.epoxyCombined
        );
    }

    constructor(
        productExtendedDto: Partial<ProductExtendedDto> | ProductExtended,
        parentId?: string,
    ) {
        Object.assign(this, { ...productExtendedDto });
        this.type = productExtendedDto?.type;
        this.id = productExtendedDto?.id || '';
        this.width = productExtendedDto?.width || 0;
        this.height = productExtendedDto?.height || 0;
        this.placementOptions = [...(productExtendedDto?.placementOptions || [])];
        if (!!productExtendedDto?.image) {
            this.image = assetImageMapper(productExtendedDto?.image);
        }
        this.parentId = productExtendedDto?.parentId || parentId || '';
    }

    static fromDynamicProduct(product: DynamicProduct | null): ProductExtended | undefined {
        if (!product) {
            return;
        }

        return new ProductExtended(product);
    }

    updateModel(partial: Partial<ProductExtended>): ProductExtended {
        Object.assign(this, partial);
        return this;
    }

    generateTileOptions(): ProductExtended {
        this.tileOptions = this.tileOptions
            .filter((tileOption, index) => this.combined[index])
            .map(tileOption => this.convertTileToMeters(tileOption));
        this.combined = this.combined
            ? (this.combined as Array<any>).filter(combined => combined)
            : [];
        this.groupedTileOptions = this.groupByShorterDimension(this.tileOptions);
        return this;
    }

    hasSupportedModel3D(os: OS, variantIndex: number): boolean {
        const tileOption = this.tileOptions?.[variantIndex];
        return (
            (os === OS.ios && !!tileOption?.model3D?.usdz) ||
            ((os === OS.android || os === OS.windows) && !!tileOption?.model3D?.gltf)
        );
    }

    findTileGroupIndex(selectedIndex: number): number {
        let selectedTile = this.tileOptions[selectedIndex];

        if (!selectedTile && this.tileOptions.length) {
            selectedTile = this.tileOptions[0];
        }

        const tileGroupIndex = (this.groupedTileOptions || []).findIndex(
            element => !!element?.id && element?.id === selectedTile?.id,
        );

        return tileGroupIndex !== -1 ? tileGroupIndex : 0;
    }

    getPreferredPattern(pattern?: FloorPattern): FloorPattern {
        const defaultPattern = this.defaultPattern || this.patterns?.[0] || FloorPattern.random;
        return pattern && this.patterns?.includes(pattern) ? pattern : defaultPattern;
    }

    private groupByShorterDimension(tileOptions: ProductTileSize[]): ProductTileSize[] {
        const groupBy = (
            list: ProductTileSize[],
            keyGetter: (keyGetter: ProductTileSize) => number,
        ): ProductTileSize[] => {
            const localMap = new Map();
            list.forEach(item => {
                const key = keyGetter(item);
                const collection = localMap.get(key);
                if (!collection) {
                    localMap.set(key, [item]);
                } else {
                    collection.push(item);
                }
            });
            return Array.from(localMap).map(value => value[1]);
        };
        const horizontal = tileOptions.filter(el => el.width >= el.height);
        const vertical = tileOptions.filter(el => el.width < el.height);

        const horizontalGroup = groupBy(horizontal, tileOption => tileOption.height);
        const verticalGroup = groupBy(vertical, tileOption => tileOption.width);

        return horizontalGroup.concat(verticalGroup);
    }

    private convertTileToMeters(tileOption: ProductTileSize): ProductTileSize {
        if (tileOption.originalWidth || tileOption.originalHeight) {
            return tileOption;
        }

        let scaleFactor = 1;
        const newTileOptions = structuredClone(tileOption);
        newTileOptions.originalUnit = tileOption.unit;
        newTileOptions.originalWidth = tileOption.width;
        newTileOptions.originalHeight = tileOption.height;
        switch (newTileOptions.unit) {
            case DimensionUnit.inch:
                scaleFactor = 0.0254;
                break;
            case DimensionUnit.mm:
                scaleFactor = 0.001;
                break;
        }
        // todo why fraction is 4 where in other places is 2?
        newTileOptions.width = Number.parseFloat((newTileOptions.width * scaleFactor).toFixed(4));
        newTileOptions.height = Number.parseFloat((newTileOptions.height * scaleFactor).toFixed(4));
        newTileOptions.unit = DimensionUnit.meter;
        newTileOptions.groutWidth = Number.parseFloat(
            (newTileOptions.groutWidth * scaleFactor).toFixed(4),
        );
        return newTileOptions;
    }
}
