import { inject, Provider } from '@angular/core';
import { FLOORI_ENV, FLOORI_WINDOW } from '@floori-web/constants';
import { FlooriEnv, FlooriWindow, GtmTrackEvent } from '@floori-web/models';
import { DOCUMENT } from '@angular/common';
import { HashMap } from '@floori/models';
import { FLOORI_GTM_PROVIDER, FlooriGtmProvider } from '../providers';

export class GtmService implements FlooriGtmProvider {
    private readonly windowRef = inject<FlooriWindow>(FLOORI_WINDOW);
    private readonly document = inject<Document>(DOCUMENT);
    private readonly env = inject<FlooriEnv>(FLOORI_ENV);
    private readonly gtmTagId = 'GTMScript';
    private readonly gtmKey = this.env.gtmKey;
    private isEnabled = true;
    private isInitialized = false;

    get dataLayer(): unknown[] {
        this.windowRef.dataLayer = this.windowRef.dataLayer || [];
        return this.windowRef.dataLayer;
    }

    enable(): void {
        this.isEnabled = true;
        if (this.gtmKey && !this.isInitialized) {
            this.addGtmToDom();
        }
    }

    disable(): void {
        this.isEnabled = false;
        const script = this.document.getElementById(this.gtmTagId);

        if (script) {
            script.remove();
        }
        this.isInitialized = false;
        this.windowRef.dataLayer = [];
    }

    addGtmToDom(): Promise<boolean> {
        if (!this.document || !this.isEnabled) {
            return Promise.resolve(false);
        }

        if (this.isInitialized) {
            return Promise.resolve(true);
        }

        this.isInitialized = true;

        return new Promise((resolve, reject) => {
            const doc = this.document;
            this.pushOnDataLayer({
                // naming convention for GTM
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'gtm.start': new Date().getTime(),
                // Disabled quote-props because once deleted prettier puts them back
                // eslint-disable-next-line quote-props
                'event': 'gtm.js',
            });
            const gtmScript = doc.createElement('script');
            gtmScript.id = this.gtmTagId;
            gtmScript.async = true;
            gtmScript.defer = true;
            gtmScript.src = this.applyGtmKey();
            gtmScript.addEventListener('load', () => resolve(true));
            gtmScript.addEventListener('error', () => reject(false));
            try {
                doc.head.insertBefore(gtmScript, doc.head.firstChild);
            } catch (e) {
                this.isInitialized = false;
                resolve(false);
            }
        });
    }

    pushOnDataLayer(obj: GtmTrackEvent): void {
        if (!obj?.event || !this.isEnabled) {
            return;
        }

        this.dataLayer?.push({ ...obj });
    }

    track(eventName: string, props: HashMap): void {
        if (!this.isEnabled) {
            return;
        }

        this.pushOnDataLayer({
            event: eventName,
            ...props,
        });
    }

    private applyGtmKey(): string {
        return new URL(`gtm.js?id=${this.gtmKey}`, this.env.gtmUrl).href;
    }
}

export const gtmProvider: Provider = {
    provide: FLOORI_GTM_PROVIDER,
    useClass: GtmService,
};
