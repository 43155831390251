export enum ProductStandardField {
    sku = 'sku',
    ean = 'ean',
    externalId = 'externalId',
    manufacturer = 'manufacturer',
    supplier = 'supplier',
    type = 'type',
    description = 'description',
    availability = 'availability',
}

export const productStandardFieldOrder: { [key: string]: number } = {
    [ProductStandardField.availability]: 0,
    [ProductStandardField.description]: 1,
    [ProductStandardField.sku]: 2,
    [ProductStandardField.ean]: 3,
    [ProductStandardField.externalId]: 4,
    [ProductStandardField.manufacturer]: 5,
    [ProductStandardField.supplier]: 6,
    [ProductStandardField.type]: 7,
};

export interface ProductCustomField {
    [key: string]: string[];
}
