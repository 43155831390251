import { Inject, Injectable } from '@angular/core';
import { FLOORI_WINDOW, flooriConstants } from '@floori-web/constants';
import { FlooriWindow } from '@floori-web/models';
import { map, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Platform } from '@angular/cdk/platform';
import { Orientation, OS } from '@floori/models';
import { Size } from '@floori-web/visualization-models';

@Injectable()
export class DeviceService {
    private mediaDevices: MediaDevices;
    private landscapeTypes: OrientationType[] = ['landscape-primary', 'landscape-secondary'];
    private _navigator?: Navigator = navigator;

    get orientation(): Orientation {
        if (!this.viewport) {
            return this.landscapeTypes.includes(this.windowRef?.screen?.orientation?.type)
                ? Orientation.landscape
                : Orientation.portrait;
        }
        // Leaving '!' here and checking if width and height aren't null in photoOrientation()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const { width, height } = this.windowRef.visualViewport!;
        return this.photoOrientation({ height, width }) || Orientation.portrait;
    }

    get isAndroid(): boolean {
        return this.platform.ANDROID;
    }

    get isIOS(): boolean {
        return this.platform.IOS;
    }

    get isMobile(): boolean {
        return this.platform.ANDROID || this.platform.IOS;
    }

    get isMobileBySize(): boolean {
        return (this.viewport?.width || 0) < flooriConstants.screenBreakpoints.tabletRaw;
    }

    get isTablet(): boolean {
        return (
            this.isMobile &&
            (this.windowRef.visualViewport?.width || 1) >=
                flooriConstants.screenBreakpoints.tabletRaw
        );
    }

    get isTabletBySize(): boolean {
        return (
            (this.windowRef.visualViewport?.width || 1) >=
            flooriConstants.screenBreakpoints.tabletRaw
        );
    }

    get isDesktop(): boolean {
        return !this.isTablet && !this.isMobile;
    }

    get isDesktopBySize(): boolean {
        return (
            (this.windowRef.visualViewport?.width || 1) >=
            flooriConstants.screenBreakpoints.desktopRaw
        );
    }

    get hasCamera(): Observable<boolean> {
        return fromPromise(this.mediaDevices.enumerateDevices()).pipe(
            map(devices => !!devices.find(d => d.kind === 'videoinput')),
        );
    }

    get getOperatingSystem(): OS {
        const userAgent = this.navigator?.userAgent || this.navigator?.vendor || '';
        if (/Windows/i.test(userAgent)) {
            return OS.windows;
        }

        if (/android/i.test(userAgent)) {
            return OS.android;
        }

        const iPadPro: boolean = /Macintosh/.test(userAgent) && navigator.maxTouchPoints > 0;
        if (/iPad|iPhone|iPod/.test(userAgent) || iPadPro) {
            return OS.ios;
        }

        return OS.unknown;
    }

    get isMobileLandscape(): boolean {
        const isLandscape = this.orientation === Orientation.landscape;
        return isLandscape && this.isMobileBySize;
    }

    get isLandscape(): boolean {
        return this.orientation === Orientation.landscape;
    }

    get viewport(): VisualViewport | null {
        return this.windowRef.visualViewport;
    }

    get is4k(): boolean {
        const uhd = 2048;
        const width = this.windowRef.innerWidth * this.windowRef.devicePixelRatio;
        const height = this.windowRef.innerHeight * this.windowRef.devicePixelRatio;
        return width > uhd || height > uhd;
    }

    get navigator(): Navigator | undefined {
        return this._navigator;
    }

    get isConnected(): boolean {
        return !!this._navigator?.onLine;
    }

    constructor(
        private readonly platform: Platform,
        @Inject(FLOORI_WINDOW) private readonly windowRef: FlooriWindow,
    ) {
        this.mediaDevices = this.windowRef.navigator?.mediaDevices;
    }

    photoOrientation(size?: Size): Orientation | null {
        if (!size || !size.height || !size.width) {
            return null;
        }
        return size.width > size.height ? Orientation.landscape : Orientation.portrait;
    }

    photoOrientationMatching(size: Size | undefined): boolean {
        if (!size) {
            return true;
        }
        const orientation = this.orientation;
        const photoOrientation = this.photoOrientation(size);
        return orientation === photoOrientation;
    }
}
