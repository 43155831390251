export const isoLanguages: Record<string, string> = {
    ara: 'ar',
    bg: 'bg',
    bl: 'nl-BE',
    ch: 'zh',
    cs: 'cs',
    da: 'da',
    de: 'de',
    en: 'en',
    us: 'en-US',
    au: 'en-AU',
    es: 'es',
    fi: 'fi',
    fr: 'fr',
    gr: 'el',
    hr: 'hr',
    hu: 'hu',
    id: 'id',
    il: 'he',
    it: 'it',
    ja: 'ja',
    kr: 'ko',
    lt: 'lt',
    nl: 'nl',
    no: 'no',
    pl: 'pl',
    ptbr: 'pt-BR',
    pt: 'pt',
    ro: 'ro',
    ru: 'ru',
    sa: 'ar-SA',
    se: 'sv',
    sk: 'sk',
    sl: 'sl',
    sr: 'sr',
    tr: 'tr',
    th: 'th',
    uk: 'ua',
    vie: 'vi',
} as const;

export const isoToFlooriLangs: Record<string, string> = Object.keys(isoLanguages).reduce(
    (ret: Record<string, string>, key) => {
        ret[isoLanguages[key]] = key;
        return ret;
    },
    {},
);
